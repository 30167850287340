// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CheckboxScss from "./Checkbox.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = CheckboxScss;

function Checkbox$BaseCheckbox(props) {
  var onClick = props.onClick;
  var __className = props.className;
  var __visuallyDisabled = props.visuallyDisabled;
  var __disabled = props.disabled;
  var id = props.id;
  var disabled = __disabled !== undefined ? __disabled : false;
  var visuallyDisabled = __visuallyDisabled !== undefined ? __visuallyDisabled : false;
  var className = __className !== undefined ? __className : "";
  var checkboxRef = React.useRef(null);
  var handleClick = function ($$event) {
    var checkbox = checkboxRef.current;
    if (!(checkbox == null)) {
      if (onClick !== undefined) {
        onClick($$event);
      }
      checkbox.blur();
      return ;
    }
    
  };
  var tmp;
  switch (props.size) {
    case "SM" :
        tmp = css.sizeSm;
        break;
    case "MD" :
        tmp = css.sizeMd;
        break;
    case "LG" :
        tmp = css.sizeLg;
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("input", {
                      ref: Caml_option.some(checkboxRef),
                      className: Cx.cx([
                            css.checkbox,
                            visuallyDisabled ? css.visuallyDisabled : ""
                          ]),
                      id: id,
                      checked: props.checked,
                      disabled: disabled || visuallyDisabled,
                      type: "checkbox",
                      onBlur: props.onBlur,
                      onChange: props.onChange,
                      onClick: handleClick
                    }),
                JsxRuntime.jsx("label", {
                      className: css.label,
                      htmlFor: id
                    })
              ],
              className: Cx.cx([
                    css.wrapper,
                    tmp,
                    className
                  ])
            });
}

var BaseCheckbox = {
  make: Checkbox$BaseCheckbox
};

function Checkbox$WithLabel(props) {
  var __containerClassName = props.containerClassName;
  var __className = props.className;
  var __visuallyDisabled = props.visuallyDisabled;
  var __disabled = props.disabled;
  var id = props.id;
  var disabled = __disabled !== undefined ? __disabled : false;
  var visuallyDisabled = __visuallyDisabled !== undefined ? __visuallyDisabled : false;
  var className = __className !== undefined ? __className : "";
  var containerClassName = __containerClassName !== undefined ? __containerClassName : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Checkbox$BaseCheckbox, {
                            id: id,
                            size: props.size,
                            checked: props.checked,
                            disabled: disabled,
                            visuallyDisabled: visuallyDisabled,
                            className: className,
                            onChange: props.onChange,
                            onBlur: props.onBlur,
                            onClick: props.onClick
                          })
                    }),
                JsxRuntime.jsx("label", {
                      children: props.children,
                      className: css.textLabel,
                      htmlFor: id
                    })
              ],
              className: Cx.cx([
                    css.withLabel,
                    containerClassName
                  ])
            });
}

var WithLabel = {
  make: Checkbox$WithLabel
};

var make = Checkbox$BaseCheckbox;

export {
  css ,
  BaseCheckbox ,
  WithLabel ,
  make ,
}
/* css Not a pure module */
