// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as TelephoneFieldScss from "./TelephoneField.scss";
import ReactTelephoneInput from "react-telephone-input";
import FlagsPng from "app/assets/images/flags.png";

var css = TelephoneFieldScss;

function TelephoneField(props) {
  var __wrapperClassName = props.wrapperClassName;
  var status = props.status;
  var disabled = props.disabled;
  var wrapperClassName = __wrapperClassName !== undefined ? __wrapperClassName : "";
  var tmp;
  tmp = status !== undefined && status !== "Valid" ? css.error : "";
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ReactTelephoneInput, {
                    value: props.value,
                    defaultCountry: props.defaultCountry,
                    onChange: props.onChange,
                    inputProps: {
                      id: props.id
                    },
                    disabled: disabled,
                    classNames: Cx.cx([
                          css.input,
                          disabled !== undefined && disabled ? css.disabled : "",
                          tmp
                        ]),
                    flagsImagePath: FlagsPng,
                    preferredCountries: props.preferredCountries,
                    autoComplete: "Notel"
                  }),
              className: Cx.cx([
                    css.wrapper,
                    wrapperClassName
                  ])
            });
}

var ReactTelInputData;

var make = TelephoneField;

export {
  css ,
  ReactTelInputData ,
  make ,
}
/* css Not a pure module */
