// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function show(stateSlug, countrySlug) {
  return "/locations/" + encodeURI(countrySlug) + "/" + encodeURI(stateSlug);
}

function edit(id) {
  return "/dashboard/states/" + ID.toString(id) + "/edit";
}

var Dashboard = {
  index: "/dashboard/states",
  edit: edit
};

var california = show("california", "united-states");

var texas = show("texas", "united-states");

var virginia = show("viginia", "united-states");

var illinois = show("illinois", "united-states");

var england = show("england", "united-kingdom");

var index = "/locations/states";

export {
  index ,
  show ,
  Dashboard ,
  california ,
  texas ,
  virginia ,
  illinois ,
  england ,
}
/* california Not a pure module */
