// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Screens from "../../styles/Screens.res.js";
import * as Cloudinary from "../../../libs/Cloudinary.res.js";
import * as ImageWithFallback from "../../components/Image/ImageWithFallback.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function buildUrl(cropOpt, width, height, src) {
  var crop = cropOpt !== undefined ? cropOpt : "Fill";
  return Cloudinary.Url.build("Png", {
              hd: "Dpr",
              tl: {
                hd: "Quality",
                tl: {
                  hd: {
                    TAG: "Crop",
                    _0: crop
                  },
                  tl: {
                    hd: {
                      TAG: "FetchFormat",
                      _0: "Png"
                    },
                    tl: {
                      hd: {
                        TAG: "Width",
                        _0: width
                      },
                      tl: {
                        hd: {
                          TAG: "Height",
                          _0: height
                        },
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }, src);
}

function Picture(props) {
  var fallbackSrc = props.fallbackSrc;
  var imageClassName = props.imageClassName;
  var crop = props.crop;
  var small = props.small;
  var medium = props.medium;
  var large = props.large;
  var src = props.src;
  return JsxRuntime.jsxs("picture", {
              children: [
                small !== undefined ? JsxRuntime.jsx("source", {
                        media: "(max-width: " + (String(Screens.medium) + "px)"),
                        srcSet: (function (__x) {
                              return buildUrl(crop, small[0], small[1], __x);
                            })(src)
                      }) : null,
                medium !== undefined ? JsxRuntime.jsx("source", {
                        media: "(max-width: " + (String(Screens.large) + "px)"),
                        srcSet: buildUrl(crop, medium[0], medium[1], src)
                      }) : null,
                fallbackSrc !== undefined ? JsxRuntime.jsx(ImageWithFallback.make, {
                        src: buildUrl(crop, large[0], large[1], src),
                        className: imageClassName,
                        fallbackSrc: buildUrl(crop, large[0], large[1], fallbackSrc)
                      }) : JsxRuntime.jsx("img", {
                        className: imageClassName,
                        src: buildUrl(crop, large[0], large[1], src)
                      })
              ],
              className: props.className
            });
}

var Crop;

var make = Picture;

export {
  Crop ,
  buildUrl ,
  make ,
}
/* Screens Not a pure module */
