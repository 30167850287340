// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DefaultCss from "react-telephone-input/css/default.css";

function countryFromJs(js) {
  return {
          name: js.name,
          dialCode: js.dialCode,
          format: undefined,
          iso2: js.iso2,
          priority: undefined
        };
}

var ReactTelInputData = {
  countryFromJs: countryFromJs
};

export {
  ReactTelInputData ,
}
/*  Not a pure module */
