// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconEdit(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Edit";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: "20",
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M11 6l3 3-8 8H3v-3l8-8zm4.7-3.3l1.6 1.6c.4.4.4 1 0 1.4L15 8l-3-3 2.3-2.3a1 1 0 011.4 0z",
                    fill: Icon.mapColor(props.color),
                    fillRule: "nonzero"
                  })
            });
}

var make = IconEdit;

export {
  make ,
}
/* Icon Not a pure module */
