// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Events from "../../../libs/Events.res.js";
import * as Portal from "../../components/Portal/Portal.res.js";
import * as Control from "../../components/Control/Control.res.js";
import * as Keyboard from "../../../libs/Keyboard.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as CreateModalLead from "./form/CreateModalLead.res.js";
import * as LeadModalScss from "./LeadModal.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = LeadModalScss;

function LeadModal(props) {
  var close = props.close;
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToKeyDown(function ($$event) {
                      Keyboard.Dom.onEsc($$event, close);
                    });
        }), []);
  return JsxRuntime.jsx(Portal.make, {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            className: css.modalOverlay,
                            onClick: (function (param) {
                                close();
                              })
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(Control.make, {
                                          className: css.closeOverlayButton,
                                          onClick: (function (param) {
                                              close();
                                            }),
                                          children: JsxRuntime.jsx(IconClose.make, {
                                                title: "Close",
                                                size: "MD",
                                                color: "Gray"
                                              })
                                        }),
                                    JsxRuntime.jsx("h2", {
                                          children: props.title
                                        }),
                                    JsxRuntime.jsx("h3", {
                                          children: props.subtitle
                                        }),
                                    JsxRuntime.jsx(CreateModalLead.make, {
                                          buttonTitle: props.buttonTitle,
                                          leadType: props.leadType
                                        })
                                  ],
                                  className: css.modalContents
                                }),
                            className: css.sideModal,
                            id: "sideModal"
                          })
                    ],
                    className: css.sideModalContainer,
                    id: "sideModalContainer"
                  })
            });
}

var make = LeadModal;

export {
  css ,
  make ,
}
/* css Not a pure module */
