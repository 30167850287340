// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function show(citySlug, stateSlug, countrySlug) {
  return "/locations/" + encodeURI(countrySlug) + "/" + encodeURI(stateSlug) + "/" + encodeURI(citySlug);
}

function edit(id) {
  return "/dashboard/cities/" + ID.toString(id) + "/edit";
}

var Dashboard = {
  index: "/dashboard/cities",
  edit: edit
};

var ashburn = show("ashburn", "virginia", "united-states");

var sanJose = show("san-jose", "california", "united-states");

var frankfurt = show("frankfurt-am-main", "hessen", "germany");

var miami = show("miami", "florida", "united-states");

var dallas = show("dallas", "texas", "united-states");

var chicago = show("chicago", "illinois", "united-states");

var london = show("london", "england", "united-kingdom");

var losAngeles = show("los-angeles", "california", "united-states");

var saoPaulo = show("sao-paulo", "sao-paulo", "brazil");

var index = "/locations/cities";

export {
  index ,
  show ,
  Dashboard ,
  ashburn ,
  sanJose ,
  frankfurt ,
  miami ,
  dallas ,
  chicago ,
  london ,
  losAngeles ,
  saoPaulo ,
}
/* ashburn Not a pure module */
