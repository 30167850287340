// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as LinkScss from "./Link.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = LinkScss;

function A(props) {
  var __className = props.className;
  var targetBlank = props.targetBlank;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("a", {
              children: props.children,
              ref: props.elRef,
              className: Cx.cx([className.length !== 0 ? className : css.link]),
              id: props.id,
              download: props.download,
              href: props.href,
              rel: targetBlank !== undefined && targetBlank ? "noopener noreferrer" : undefined,
              target: targetBlank !== undefined && targetBlank ? "_blank" : undefined,
              onClick: props.onClick
            });
}

var make = A;

export {
  css ,
  make ,
}
/* css Not a pure module */
