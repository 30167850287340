// Generated by ReScript, PLEASE EDIT WITH CARE


var LocaleString = {};

function formatWithFractionDigits(x, digits) {
  return x.toLocaleString("en", {
              minimumFractionDigits: 0,
              maximumFractionDigits: digits
            });
}

function formatWithoutFractionDigits(x) {
  return x.toLocaleString("en", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            });
}

export {
  LocaleString ,
  formatWithFractionDigits ,
  formatWithoutFractionDigits ,
}
/* No side effect */
