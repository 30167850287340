// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Char from "../../../libs/Char.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as BreadcrumbScss from "./Breadcrumb.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = BreadcrumbScss;

function Breadcrumb(props) {
  var __breadcrumbLinkClassName = props.breadcrumbLinkClassName;
  var __wrapperContainerClassName = props.wrapperContainerClassName;
  var breadcrumbLinks = props.breadcrumbLinks;
  var wrapperContainerClassName = __wrapperContainerClassName !== undefined ? __wrapperContainerClassName : "";
  var breadcrumbLinkClassName = __breadcrumbLinkClassName !== undefined ? __breadcrumbLinkClassName : "";
  return JsxRuntime.jsx("div", {
              children: Belt_Array.mapWithIndex(breadcrumbLinks, (function (index, breadcrumbLink) {
                      return JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(A.make, {
                                          href: breadcrumbLink.linkPath,
                                          className: Cx.cx([
                                                css.breadcrumbLink,
                                                breadcrumbLinkClassName
                                              ]),
                                          children: breadcrumbLink.linkName
                                        }),
                                    index !== (breadcrumbLinks.length - 1 | 0) ? JsxRuntime.jsx("span", {
                                            children: JsxRuntime.jsx(Char.RightCaret.make, {})
                                          }) : null
                                  ],
                                  className: css.breadcrumb
                                }, String(index));
                    })),
              className: Cx.cx([
                    css.breadcrumbContainer,
                    wrapperContainerClassName
                  ])
            });
}

var make = Breadcrumb;

export {
  css ,
  make ,
}
/* css Not a pure module */
