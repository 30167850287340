// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";

function ImageWithFallback(props) {
  var src = props.src;
  var match = React.useState(function () {
        return false;
      });
  var setErrored = match[1];
  var errored = match[0];
  React.useEffect((function () {
          setErrored(function (param) {
                return false;
              });
        }), [src]);
  return JsxRuntime.jsx("img", {
              className: props.className,
              src: errored ? props.fallbackSrc : src,
              onError: (function (param) {
                  if (!errored) {
                    return setErrored(function (param) {
                                return true;
                              });
                  }
                  
                })
            });
}

var make = ImageWithFallback;

export {
  make ,
}
/* react Not a pure module */
