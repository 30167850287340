// Generated by ReScript, PLEASE EDIT WITH CARE


var confirmed = "/leads/confirmed";

var bareMetal = "/leads/bare_metal";

var zenlayerBareMetal = "/leads/zenlayer_bare_metal";

var enzuBareMetal = "/leads/enzu_bare_metal";

var hivelocityBareMetal = "/leads/hivelocity_bare_metal";

var evocativeBareMetal = "/leads/evocative_bare_metal";

var colocation = "/leads/colocation";

var multicloud = "/leads/multicloud";

export {
  confirmed ,
  bareMetal ,
  zenlayerBareMetal ,
  enzuBareMetal ,
  hivelocityBareMetal ,
  evocativeBareMetal ,
  colocation ,
  multicloud ,
}
/* No side effect */
