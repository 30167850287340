// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDom from "react-dom";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi__Dom__Document from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Document.res.js";

function Portal(props) {
  var el = React.useMemo((function () {
          var element;
          try {
            element = document.createElement("div");
          }
          catch (exn){
            return ;
          }
          return Caml_option.some(element);
        }), []);
  var el$1 = React.useRef(el);
  React.useEffect((function () {
          var body = Belt_Option.getExn(Caml_option.nullable_to_opt(Belt_Option.getExn(Webapi__Dom__Document.asHtmlDocument(document)).body));
          body.appendChild(Belt_Option.getExn(el$1.current));
          return (function () {
                    var x;
                    try {
                      x = body.removeChild(Belt_Option.getExn(el$1.current));
                    }
                    catch (exn){
                      return ;
                    }
                  });
        }), []);
  var el$2 = el$1.current;
  if (el$2 !== undefined) {
    return ReactDom.createPortal(props.children, Caml_option.valFromOption(el$2));
  } else {
    return null;
  }
}

var make = Portal;

export {
  make ,
}
/* react Not a pure module */
