// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconDownload(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Download";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("g", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("path", {
                                d: "M2,6 L4,6 L4,16 L16,16 L16,6 L18,6 L18,17 C18,17.5522847 17.5522847,18 17,18 L3,18 C2.44771525,18 2,17.5522847 2,17 L2,6 Z M9,2 L11,2 L11,11.5 L12.75,9.75 L14,11 L10,15 L6,11 L7.25,9.75 L9,11.5 L9,2 Z"
                              }),
                          fill: Icon.mapColor(props.color),
                          fillRule: "nonzero",
                          transform: "translate(-2.000000, -2.000000)"
                        }),
                    fill: "none",
                    fillRule: "evenodd",
                    stroke: "none",
                    strokeWidth: "1"
                  })
            });
}

var make = IconDownload;

export {
  make ,
}
/* Icon Not a pure module */
