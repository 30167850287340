// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Number from "./Number.res.js";

function format(x) {
  return "$" + $$Number.formatWithoutFractionDigits(x);
}

function formatWithCents(x) {
  return "$" + $$Number.formatWithFractionDigits(x, 2);
}

export {
  format ,
  formatWithCents ,
}
/* No side effect */
