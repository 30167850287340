// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Form from "../../../forms/Form/Form.res.js";
import * as Checkbox from "../../../forms/Checkbox/Checkbox.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../../forms/TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ErrorMessage from "../../../forms/ErrorMessage/ErrorMessage.res.js";
import * as ReactTelInput from "../../../../bindings/ReactTelInput.res.js";
import * as TelephoneField from "../../../forms/TelephoneField/TelephoneField.res.js";
import * as LeadModalScss from "../LeadModal.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = LeadModalScss;

function LeadModalForm(props) {
  var update = props.update;
  var results = props.results;
  var input = props.input;
  var tmp;
  if (results !== undefined) {
    var message = results.name;
    tmp = message.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message._0
          });
  } else {
    tmp = null;
  }
  var tmp$1;
  if (results !== undefined) {
    var message$1 = results.company;
    tmp$1 = message$1.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$1._0
          });
  } else {
    tmp$1 = null;
  }
  var tmp$2;
  if (results !== undefined) {
    var message$2 = results.email;
    tmp$2 = message$2.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$2._0
          });
  } else {
    tmp$2 = null;
  }
  var tmp$3;
  if (results !== undefined) {
    var message$3 = results.phone;
    tmp$3 = message$3.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$3._0
          });
  } else {
    tmp$3 = null;
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Form.make, {
                    className: css.form,
                    onSubmit: props.submit,
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "lead-form--name",
                                            value: input.name,
                                            placeholder: "Full name",
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.name.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      name: $$event.target.value,
                                                      company: input.company,
                                                      email: input.email,
                                                      phone: input.phone,
                                                      newsletterSubscription: input.newsletterSubscription,
                                                      dialCode: input.dialCode
                                                    });
                                              })
                                          }),
                                      tmp
                                    ],
                                    className: Cx.cx([
                                          css.field,
                                          css.field2_md_lg
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "lead-form--company",
                                            value: input.company,
                                            placeholder: "Company name",
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.company.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      name: input.name,
                                                      company: $$event.target.value,
                                                      email: input.email,
                                                      phone: input.phone,
                                                      newsletterSubscription: input.newsletterSubscription,
                                                      dialCode: input.dialCode
                                                    });
                                              })
                                          }),
                                      tmp$1
                                    ],
                                    className: Cx.cx([
                                          css.field,
                                          css.field2_md_lg
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "lead-form--email",
                                            value: input.email,
                                            placeholder: "Email",
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.email.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function ($$event) {
                                                update({
                                                      name: input.name,
                                                      company: input.company,
                                                      email: $$event.target.value,
                                                      phone: input.phone,
                                                      newsletterSubscription: input.newsletterSubscription,
                                                      dialCode: input.dialCode
                                                    });
                                              })
                                          }),
                                      tmp$2
                                    ],
                                    className: Cx.cx([
                                          css.field,
                                          css.field2_md_lg
                                        ])
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(TelephoneField.make, {
                                            id: "lead-form--phone",
                                            value: input.phone,
                                            defaultCountry: "us",
                                            status: Belt_Option.map(results, (function (x) {
                                                    if (x.phone.TAG === "Ok") {
                                                      return "Valid";
                                                    } else {
                                                      return "Error";
                                                    }
                                                  })),
                                            onChange: (function (value, selectedCountry) {
                                                var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                                update({
                                                      name: input.name,
                                                      company: input.company,
                                                      email: input.email,
                                                      phone: "+" + (country.dialCode + Js_string.replace("+" + country.dialCode, "", value).trim()),
                                                      newsletterSubscription: input.newsletterSubscription,
                                                      dialCode: "+" + country.dialCode
                                                    });
                                              }),
                                            preferredCountries: ["us"]
                                          }),
                                      tmp$3
                                    ],
                                    className: Cx.cx([
                                          css.field,
                                          css.field2_md_lg
                                        ])
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                          id: "reservation-form--newsletterSubscription",
                                          size: "LG",
                                          checked: input.newsletterSubscription,
                                          onChange: (function ($$event) {
                                              update({
                                                    name: input.name,
                                                    company: input.company,
                                                    email: input.email,
                                                    phone: input.phone,
                                                    newsletterSubscription: $$event.target.checked,
                                                    dialCode: input.dialCode
                                                  });
                                            }),
                                          children: "Subscribe to our newsletter"
                                        }),
                                    className: Cx.cx([
                                          css.field,
                                          css.fieldGroup1_md_lg
                                        ])
                                  })
                            ],
                            className: css.fieldGroup_md_lg
                          }),
                      props.children
                    ]
                  }),
              className: css.formContainer
            });
}

var ReactTelInputData;

var make = LeadModalForm;

export {
  css ,
  ReactTelInputData ,
  make ,
}
/* css Not a pure module */
