// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function Form(props) {
  var onSubmit = props.onSubmit;
  return JsxRuntime.jsx("form", {
              children: props.children,
              ref: props.setRef,
              className: props.className,
              id: props.id,
              onSubmit: (function ($$event) {
                  if (onSubmit !== undefined) {
                    $$event.preventDefault();
                    return onSubmit($$event);
                  }
                  
                })
            });
}

var make = Form;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
