// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconClose(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Close";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("polygon", {
                    fill: Icon.mapColor(props.color),
                    points: "0 2 1.99986545 0 8 6.00642555 14 0 16 2 10.0010801 8 16 14 14 16 8 9.99529587 1.99986545 16 0 14 6.00510581 8"
                  })
            });
}

var make = IconClose;

export {
  make ,
}
/* Icon Not a pure module */
