// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function edit(locationId, providerSlug) {
  return "/dashboard/providers/" + providerSlug + "/edit?location_id=" + ID.toString(locationId);
}

var Dashboard = {
  index: "/dashboard/locations",
  edit: edit
};

var index = "/locations";

var thankYou = "/locations/thank_you";

export {
  index ,
  thankYou ,
  Dashboard ,
}
/* ID Not a pure module */
