// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function colorToClass(color) {
  switch (color) {
    case "Gray" :
        return "text-gray";
    case "Purple" :
        return "text-purple";
    case "Black" :
        return "text-black";
    
  }
}

function weightToClass(bold) {
  switch (bold) {
    case "Bold" :
        return "font-bold";
    case "SemiBold" :
        return "font-semibold";
    case "Medium" :
        return "font-medium";
    case "Normal" :
        return "font-normal";
    
  }
}

function sizeToClass(size) {
  switch (size) {
    case "SM" :
        return "text-sm mb-4";
    case "MD" :
        return "text-base mb-4";
    case "LG" :
        return "text-lg mb-6";
    
  }
}

function P(props) {
  var __size = props.size;
  var __weight = props.weight;
  var __color = props.color;
  var color = __color !== undefined ? __color : "Gray";
  var weight = __weight !== undefined ? __weight : "Normal";
  var size = __size !== undefined ? __size : "MD";
  return JsxRuntime.jsx("p", {
              children: props.children,
              className: Cx.cx([
                    sizeToClass(size),
                    colorToClass(color),
                    weightToClass(weight)
                  ])
            });
}

var make = P;

export {
  colorToClass ,
  weightToClass ,
  sizeToClass ,
  make ,
}
/* react/jsx-runtime Not a pure module */
