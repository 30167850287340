// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Button from "../../../components/Button/Button.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Routes_Lead from "../../../../routes/common/Routes_Lead.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as LeadModalData from "./LeadModalData.res.js";
import * as LeadModalForm from "./LeadModalForm.res.js";
import * as LeadModalScss from "../LeadModal.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = LeadModalScss;

var initialState_input = LeadModalData.Input.empty();

var initialState = {
  status: "Editing",
  input: initialState_input,
  results: undefined,
  submissionError: false
};

function CreateModalLead(props) {
  var leadType = props.leadType;
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Validate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            input: state.input,
                            results: LeadModalData.Validate.all(state.input),
                            submissionError: state.submissionError
                          }
                        };
              case "Submit" :
                  var match = state.status;
                  if (match === "Editing") {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              input: state.input,
                              results: LeadModalData.Validate.all(state.input),
                              submissionError: state.submissionError
                            },
                            _1: (function (param) {
                                if (LeadModalData.Validate.valid(Belt_Option.getExn(param.state.results))) {
                                  return param.dispatch("CreateModalLead");
                                }
                                
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "CreateModalLead" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "Submitting",
                            input: state.input,
                            results: state.results,
                            submissionError: state.submissionError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.createModalLead(param.state.input), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch("GoToLeadConfirmation");
                                      }
                                      SentryLogger.error1({
                                            rootModule: "CreateModalLead",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "CreateModalLead.make"
                                          }, "CreateModalLead::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailSubmission");
                                    }));
                            })
                        };
              case "GoToLeadConfirmation" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              switch (leadType) {
                                case "General" :
                                    return Url.visit(Routes_Lead.confirmed);
                                case "BareMetal" :
                                    return Url.visit(Routes_Lead.bareMetal);
                                case "ZenlayerBareMetal" :
                                    return Url.visit(Routes_Lead.zenlayerBareMetal);
                                case "EnzuBareMetal" :
                                    return Url.visit(Routes_Lead.enzuBareMetal);
                                case "HivelocityBareMetal" :
                                    return Url.visit(Routes_Lead.hivelocityBareMetal);
                                case "EvocativeBareMetal" :
                                    return Url.visit(Routes_Lead.evocativeBareMetal);
                                case "Multicloud" :
                                    return Url.visit(Routes_Lead.multicloud);
                                case "Colocation" :
                                    return Url.visit(Routes_Lead.colocation);
                                
                              }
                            })
                        };
              case "FailSubmission" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "Editing",
                            input: state.input,
                            results: state.results,
                            submissionError: true
                          }
                        };
              
            }
          } else {
            var input = action._0;
            var match$1 = state.status;
            if (match$1 !== "Editing") {
              return "NoUpdate";
            }
            var match$2 = state.results;
            if (match$2 !== undefined) {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        status: state.status,
                        input: input,
                        results: state.results,
                        submissionError: state.submissionError
                      },
                      _1: (function (param) {
                          param.dispatch("Validate");
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        status: state.status,
                        input: input,
                        results: state.results,
                        submissionError: state.submissionError
                      }
                    };
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.status;
  return JsxRuntime.jsxs(LeadModalForm.make, {
              input: state.input,
              results: state.results,
              update: (function (input) {
                  dispatch({
                        TAG: "Update",
                        _0: input
                      });
                }),
              submit: (function (param) {
                  dispatch("Submit");
                }),
              children: [
                JsxRuntime.jsx(Button.make, {
                      size: "LG",
                      color: "Primary",
                      expanded: true,
                      busy: match$1 !== "Editing",
                      submit: true,
                      children: props.buttonTitle
                    }),
                state.submissionError ? "Something went wrong" : null
              ]
            });
}

var Input;

var Validate;

var ValidationResult;

var make = CreateModalLead;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  initialState ,
  make ,
}
/* css Not a pure module */
